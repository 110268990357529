<template>
    <div>
        <div class="toolBar mBottom10">
            <el-select v-model="appId" placeholder="请选择" size="small" @change="handleChange" style="margin-right: 20px">
                <el-option
                        v-for="item in wxMpList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary" size='small' plain @click="add">新增公众号</el-button>
            <el-button type="primary" size='small' plain @click="syncWx">同步到微信</el-button>
        </div>

        <el-tabs type="border-card" v-model="tabIndex" @tab-click="getData">
            <el-tab-pane label="参数配置">
                <el-form ref="form" :model="wxConfig" :rules="rules" label-width="160px">
                    <el-row>
                        <el-col :sm="12">
                            <el-form-item label="公众号名称:" prop="name">
                                <el-input clearable v-model="wxConfig.name" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :sm="12">
                            <el-form-item label="AppId:" prop="appId">
                                <el-input clearable v-model="wxConfig.appId" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item label="AppSecret:" prop="secret">
                                <el-input clearable v-model="wxConfig.secret" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :sm="12">
                            <el-form-item label="Token:" prop="token">
                                <el-input clearable v-model="wxConfig.token" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item label="EncodingAESKey:" prop="aesKey">
                                <el-input clearable v-model="wxConfig.aesKey" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :sm="12">
                            <el-form-item label="Oauth2redirectUri:" prop="oauth2redirectUri">
                                <el-input clearable v-model="wxConfig.oauth2redirectUri" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>

                <el-button type="primary" class="fr" @click="submit" size="small">保存</el-button>

            </el-tab-pane>
            <el-tab-pane label="菜单配置">
                <div class="toolBar mBottom10">
                    <el-button type="primary" size='small' plain @click="add1">新增菜单</el-button>
                    <el-button type="primary" size='small' plain @click="getWxMenuFromWx">查看微信端菜单</el-button>
                </div>

                <el-table :data="menuList" size="small" class="maxWidth" stripe row-key="name"
                          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column type="index" width="50" label='序号'></el-table-column>
                    <el-table-column prop="name" label="菜单名称" align="center"></el-table-column>
                    <el-table-column prop="type" label="菜单类型" align="center"></el-table-column>
                    <el-table-column prop="url" label="菜单地址" align="center"></el-table-column>
                    <el-table-column prop="oauth2Scope" label="授权范围" align="center"></el-table-column>
                    <el-table-column prop="scopeCode" label="授权码" align="center"></el-table-column>
                    <el-table-column prop="key" label="菜单key" align="center"></el-table-column>
                    <el-table-column prop="mediaId" label="素材ID" align="center"></el-table-column>
                    <el-table-column prop="appId" label="APPID" align="center"></el-table-column>
                    <el-table-column prop="sort" label="排序" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type='text' style='margin: 0 20px;' @click="edit(scope.row)">修改
                            </el-button>
                            <el-button size="mini" type='text' class='hkDanger' @click="del(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="模板配置">
                <div class="toolBar mBottom10">
                    <el-button type="primary" size='small' plain @click="syncTemplate">同步模板</el-button>
                </div>

                <el-table :data="templateList" size="small" class="maxWidth" stripe>
                    <el-table-column type="index" width="50" label='序号'></el-table-column>
                    <el-table-column prop="templateId" label="模板ID" width="360" align="left"></el-table-column>
                    <el-table-column prop="title" label="标题" align="left"></el-table-column>
                    <el-table-column prop="primaryIndustry" label="一级行业" align="left"></el-table-column>
                    <el-table-column prop="deputyIndustry" label="二级行业" align="left"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type='text' style='margin: 0 20px;' @click="detail(scope.row)">详情
                            </el-button>
                            <el-button size="mini" type='text' class='hkDanger' @click="delTemplate(scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>

        <el-dialog :title=title :visible.sync="formVisible" v-dialogDrag :close-on-click-modal="false" width="40%">
            <el-form :model="menuForm" label-width="140px" :rules="formRules" ref="menuForm" size='small'>
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="menuId：" v-show="false">
                            <el-input clearable v-model="menuForm.menuId"/>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="上级菜单：" prop="parentId">
                            <el-cascader v-model="menuForm.parentId" size="medium" :options="menuList"
                                         :props="selectProps" change-on-select expand-trigger="hover"
                                         class="w100"></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="菜单名称：" prop="name">
                            <el-input clearable v-model="menuForm.name"/>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="菜单类型：" prop="type">
                            <el-select style="width: 100%" v-model="menuForm.type" @change="changeType"
                                       placeholder="请选择">
                                <el-option v-for="item in this.TypeOptions" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="菜单Key：" prop="key" v-if="keyVisible">
                            <el-input clearable v-model="menuForm.key"/>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="URL：" prop="url" v-if="urlVisible">
                            <el-input clearable v-model="menuForm.url"/>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="授权范围：" prop="oauth2Scope" v-if="oauth2ScopeVisible">
                            <el-select style="width: 100%" v-model="menuForm.oauth2Scope" placeholder="请选择">
                                <el-option v-for="item in this.oauth2ScopeOptions" :key="item.value" :label="item.label"
                                           :value="item.value"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="授权码：" prop="scopeCode" v-if="scopeCodeVisible">
                            <el-input clearable v-model="menuForm.scopeCode"/>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="素材ID：" prop="mediaId" v-if="mediaIdVisible">
                            <el-input clearable v-model="menuForm.mediaId"/>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="小程序appId：" prop="miniAppId" v-if="appIdVisible">
                            <el-input clearable v-model="menuForm.miniAppId"/>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="小程序页面路径：" prop="pagePath" v-if="pagePathVisible">
                            <el-input clearable v-model="menuForm.pagePath"/>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="排序：" prop="sort">
                            <el-input-number clearable v-model="menuForm.sort" :min="1"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click.native="submitForm('menuForm')" size='small'>确定</el-button>
                <el-button @click="cancel('menuForm')" size='small'>取消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="微信端菜单" :visible.sync="menuFormVisible" width="40%" v-dialogDrag>
            {{menuFromWx}}
        </el-dialog>

        <el-dialog :title=title :visible.sync="templateVisible" width="80%" v-dialogDrag>
            <el-row style="line-height: 40px">
                <el-col :sm="10" style="padding: 10px">
                    <div style="border: 1px solid #DCDFE6;margin-right: 10px;padding: 20px">
                        <div class="cmm-wrapper" v-html="templateDetail.example"></div>
                    </div>
                    <div style="border: 1px solid #DCDFE6;margin-right: 10px;padding: 5px 20px;border-top: 0;">
                        详情 <i style="line-height: 40px" class="el-icon-arrow-right fr"></i>
                    </div>
                    <div style="text-align: center">内容示例</div>
                </el-col>
                <el-col :sm="14">
                    <el-row>
                        <el-col :sm="4">
                            模板ID
                        </el-col>
                        <el-col :sm="20">
                            {{templateDetail.templateId}}<br>
                            <span style="color: #999999">开发者调用模板消息接口时需提供模板ID</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :sm="4">
                            标题
                        </el-col>
                        <el-col :sm="20">
                            {{templateDetail.title}}
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :sm="4">
                            行业
                        </el-col>
                        <el-col :sm="20">
                            {{templateDetail.primaryIndustry}} {{templateDetail.primaryIndustry}}
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :sm="4">
                            详细内容
                        </el-col>
                        <el-col :sm="20">
                            <div style="line-height: 25px" v-html="templateDetail.content"></div>
                        </el-col>
                    </el-row>
                    <span style="color: #999999">在发送时，需要将内容中的参数（{.DATA}内为参数）赋值替换为需要的信息</span>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
    class WxConfig {
        constructor() {
            this.name = null;
            this.aesKey = null;
            this.appId = null;
            this.secret = null;
            this.token = null;
            this.oauth2redirectUri=null;
        }

        reset() {
            this.constructor();
        }
    }

    class Menu {
        constructor() {
            this.menuId = null;
            this.name = null;
            this.url = null;
            this.type = null;
            this.key = null;
            this.appId = null;
            this.miniAppId = null;
            this.pagePath = null;
            this.mediaId = null;
            this.parentId = 0;
            this.sort = null;
            this.parentName = null;
            this.oauth2Scope = null;
            this.scopeCode = null;
        }

        reset() {
            this.constructor();
        }
    }

    export default {
        data() {
            return {
                tabIndex: "0",
                rules: {
                    name: [
                        {required: true, message: '公众号名称不能为空', trigger: 'blur'}
                    ],
                    appId: [
                        {required: true, message: 'AppId不能为空', trigger: 'blur'}
                    ],
                    secret: [
                        {required: true, message: 'AppSecret不能为空', trigger: 'blur'}
                    ],
                    token: [
                        {required: true, message: 'Token不能为空', trigger: 'blur'}
                    ],
                    aesKey: [
                        {required: true, message: 'EncodingAESKey不能为空', trigger: 'blur'}
                    ],
                    oauth2redirectUri:[
                        {required: true, message: 'oauth2redirectUri不能为空', trigger: 'blur'}
                    ]
                },
                wxConfig: new WxConfig(),
                menuList: [],

                title: "",
                formRules: {
                    name: [
                        {required: true, message: '菜单名称不能为空', trigger: 'blur'}
                    ],
                    type: [
                        {required: true, message: '菜单类型不能为空', trigger: 'blur'}
                    ],
                    key: [
                        {required: true, message: '菜单Key不能为空', trigger: 'blur'}
                    ],
                    url: [
                        {required: true, message: 'URL不能为空', trigger: 'blur'}
                    ],
                    mediaId: [
                        {required: true, message: '素材ID不能为空', trigger: 'blur'}
                    ],
                    miniAppId: [
                        {required: true, message: '小程序appId不能为空', trigger: 'blur'}
                    ],
                    pagePath: [
                        {required: true, message: '小程序页面路径不能为空', trigger: 'blur'}
                    ],
                },
                oauth2ScopeOptions: [{value: null, label: '不需要授权'}, {value: 'snsapi_base', label: 'SNSAPI_BASE'}, {
                    value: 'snsapi_userinfo',
                    label: 'SNSAPI_USERINFO'
                }],
                TypeOptions: [{
                    value: 'menu',
                    label: '菜单(menu)'
                }, {
                    value: 'click',
                    label: '点击推事件(click)'
                }, {
                    value: 'view',
                    label: '跳转URL(view)'
                }, {
                    value: 'miniprogram',
                    label: '跳转到小程序(miniprogram)'
                }, {
                    value: 'scancode_push',
                    label: '扫码推事件(scancode_push)'
                }, {
                    value: 'scancode_waitmsg',
                    label: '扫码推事件且弹出“消息接收中”提示框(scancode_waitmsg)'
                }, {
                    value: 'pic_sysphoto',
                    label: '弹出系统拍照发图(pic_sysphoto)'
                }, {
                    value: 'pic_photo_or_album',
                    label: '弹出拍照或者相册发图(pic_photo_or_album)'
                }, {
                    value: 'pic_weixin',
                    label: '弹出微信相册发图器(pic_weixin)'
                }, {
                    value: 'location_select',
                    label: '弹出地理位置选择器(location_select)'
                }, {
                    value: 'media_id',
                    label: '下发消息(media_id)'
                }, {
                    value: 'view_limited',
                    label: '跳转图文消息URL(view_limited)'
                }],
                menuForm: new Menu(),

                templateVisible: false,
                keyVisible: false,
                urlVisible: false,
                mediaIdVisible: false,
                appIdVisible: false,
                pagePathVisible: false,
                formLabelWidth: "100",
                formVisible: false,      //弹框是否显示
                scopeCodeVisible: false,
                oauth2ScopeVisible: false,
                tableData: [],           //表格中使用的数据
                menuTree: [],
                menuTableData: [],
                tableId: null,
                editForm: false,
                title: "",
                selectProps: {children: 'children', label: 'name', value: "menuId"},
                templateList: [],
                templateDetail: {},

                appId: [],
                wxMpList: [],
                menuFromWx: {},
                menuFormVisible: false
            };
        },
        computed: {
        },
        methods: {
            initDefault() {
                this.keyVisible = false;
                this.urlVisible = false;
                this.mediaIdVisible = false;
                this.appIdVisible = false;
                this.pagePathVisible = false;
                this.oauth2ScopeVisible = false;
                this.scopeCodeVisible = false;
            },

            async getWxMpCombo() {
                let res = await this.$http.post('/mp/getWxMpCombo', {});
                if (res.state) {
                    this.wxMpList = res.data;
                }
            },
            load() {
                if (this.tabIndex == "1") {
                    this.getMenu();
                } else if (this.tabIndex == "2") {
                    this.getTemplate();
                }
            },
            handleChange(val) {
                if (val) {
                    this.wxConfig.reset();
                    this.getWxMpConfig();
                    this.load()
                }
            },
            async addWxMpConfig() {
                let res = await this.$http.post('mp/addWxMpConfig', this.wxConfig);
                if (res.state) {
                    this.$message.success('新增成功');
                    this.wxConfig.reset();
                    this.getWxMpCombo();
                }
            },
            async getWxMpConfig(){
                let param = {appId:this.appId};
                let res = await this.$http.post('mp/getWxMpConfig', param);
                if (res.state) {
                    Object.assign(this.wxConfig,res.data);
                }
            },
            submit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.addWxMpConfig();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getData() {
                if (!this.appId) {
                    this.$message.warning('请先选择公众号！');
                    return;
                }
                if (this.tabIndex == "1") {
                    this.getMenu();
                } else if (this.tabIndex == "2") {
                    this.getTemplate();
                }
            },
            async getMenu() {
                if (!this.appId) {
                    this.$message.warning('请先选择公众号！');
                    return;
                }
                let res = await this.$http.post("/mp/getWxMpMenu", {appId: this.appId});
                if (res.state) {
                    if (res.data) {
                        this.menuList = res.data;
                    }
                }
            },

            async getWxMenuFromWx(){
                if (!this.appId) {
                    this.$message.warning('请先选择公众号！');
                    return;
                }
                let res = await this.$http.post("/mp/getWxMenuFromWx", {appId: this.appId});
                if (res.state) {
                    if (res.data) {
                        this.menuFromWx = res.data;
                        this.menuFormVisible = true;
                    }
                }
            },

            async getTemplate() {
                if (!this.appId) {
                    this.$message.warning('请先选择公众号！');
                    return;
                }
                let res = await this.$http.post("/mp/getWxMpTemplate", {appId: this.appId});
                if (res.state) {
                    if (res.data) {
                        this.templateList = res.data;
                    }
                }
            },
            add(){
                this.wxConfig.reset();
                this.appId = null;
            },
            add1() {
                this.title = '新增菜单';
                this.formVisible = true;
                this.menuForm.reset();
                this.initDefault();
                this.menuForm.parentId = 0;
                this.menuForm.parentName = '一级菜单';
            },
            edit(row) {
                this.title = '修改菜单';
                this.formVisible = true;
                this.menuForm.reset();
                this.initDefault();
                Object.assign(this.menuForm, row);
                this.changeType(this.menuForm.type);
            },
            async syncTemplate() {
                if (!this.appId) {
                    this.$message.warning('请先选择公众号！');
                    return;
                }
                let res = await this.$http.post('/mp/syncWxMpTemplate', {appId: this.appId});
                if (res.state) {
                    this.$message.success("同步成功");
                    this.load();
                }
            },
            async delRequest(menuId) {
                let res = await this.$http.post('/mp/delWxMpMenu', {menuId});
                if (res.state) {
                    this.$message.success("删除成功");
                    this.load();
                }
            },
            del(row) {
                this.$confirm('确认删除吗？', '提示', {}).then(() => {
                    this.delRequest(row.menuId);
                });
            },
            async syncWxRequest() {
                let res = await this.$http.post('/mp/syncWxMpMenuToWx', {appId: this.appId});
                if (res.state) {
                    this.$message.success("同步成功");
                    this.load();
                }
            },
            syncWx() {
                if (!this.appId) {
                    this.$message.warning('请先选择公众号！');
                    return;
                }
                this.$confirm('确定同步到微信端？', '提示', {}).then(() => {
                    this.syncWxRequest();
                });
            },
            async addWxMenu() {
                if (!this.appId) {
                    this.$message.warning('请先选择公众号！');
                    return;
                }
                this.menuForm.appId = this.appId;
                this.menuForm.parentId = this.menuForm.parentId ? this.menuForm.parentId[0] : 0;
                let res = await this.$http.post('/mp/addWxMpMenu', this.menuForm);
                if (res.state) {
                    this.$message.success("新增成功");
                    this.formVisible = false;
                    this.load();
                }
            },
            async editWxMenu() {
                let res = await this.$http.post('/mp/updWxMpMenu', this.menuForm);
                if (res.state) {
                    this.$message.success("修改成功");
                    this.formVisible = false;
                    this.load();
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm('确认提交吗？', '提示', {}).then(() => {
                            if (this.title == '新增菜单') {
                                this.addWxMenu();
                            } else if (this.title == '修改菜单') {
                                if (this.menuForm.menuId == this.menuForm.parentId) {
                                    this.$message.error("上级菜单不能是自己");
                                    return;
                                }
                                this.editWxMenu();
                            } else {
                                this.$message.error("不支持的操作");
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.formVisible = false;
            },
            changeType(val) {
                this.menuForm.type = val;
                this.initDefault();
                if (this.menuForm.type == 'view') {
                    this.urlVisible = true;
                    this.oauth2ScopeVisible = true;
                    this.scopeCodeVisible = true;
                } else if (this.menuForm.type == 'miniprogram') {
                    this.urlVisible = true;
                    this.appIdVisible = true;
                    this.pagePathVisible = true;
                } else if (this.menuForm.type == 'media_id' || this.menuForm.type == 'view_limited') {
                    this.mediaIdVisible = true;
                } else if (this.menuForm.type == 'menu') {
                    //do nothing
                } else {
                    this.keyVisible = true;
                }
            },
            replaceStr(str){
                return str.replace('\n','<br/>');
            },
            detail(row) {
                this.templateDetail = JSON.parse(JSON.stringify(row));
                let ex = JSON.parse(this.templateDetail.example);
                let co = JSON.parse(this.templateDetail.content);
                this.templateDetail.example = ex.replace(/\n/g,"<br>");
                this.templateDetail.content = co.replace(/\n/g,"<br>");
                this.templateVisible = true;
            },
            async delTemplateRequest(id) {
                let param = {
                    appId: this.appId,
                    templateId: id
                };
                let res = await this.$http.post('/mp/delWxMpTemplate', param);
                if (res.state) {
                    this.$message.success("删除成功");
                    this.load();
                }
            },
            delTemplate(row) {
                this.$confirm('确认删除吗？', '提示', {}).then(() => {
                    this.delTemplateRequest(row.templateId);
                });
            },
        },
        created() {
            if (this.appId) {
                this.getWxMpCombo();
                this.load();
            }
        },
    };
</script>
<style scoped>
    .cmm-wrapper {
        white-space: pre-wrap;
        text-align: left;
        line-height: 25px;
    }
</style>